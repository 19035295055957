<template>
  <div class="admin-invite">
    <b-container>
      <h4>Welcome to SolidProfessor!</h4>
      <p class="invite-text">
        Invite the rest of your team to join SolidProfessor. Simply share the unique sign-up link and they'll be able
        to create an account.
      </p>
      <b-row>
        <b-col>
          <ShareLinkForm :clipboard-value="clipboardValue" />
          <b-link :href="'mailto:?subject=' + emailText.subject + '&body=' + getEmailBody()">
            <p><font-awesome-icon icon="envelope" /> &nbsp;Share via email</p>
          </b-link>
        </b-col>
      </b-row>
      <b-row class="m-3">
        <b-col class="text-center">
          <b-btn variant="primary" :href="SPENV.APP_URL + '/lmsapp/multigroups/list'">
            Continue to SolidProfessor
          </b-btn>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-center">
          <b-link :href="SPENV.SUPPORT_URL">
            Need help? Contact support
          </b-link>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import redirectMixin from '@/utils/mixins/Redirect';
import loginRedirectMixin from '@/utils/mixins/LoginRedirect';
import SpLoader from '@/components/SPLoader';

import ShareLinkForm from '@/components/ShareLinkForm';

export default {
  name: 'AdminVue',
  components: {
    'sp-loader': SpLoader,
    ShareLinkForm
  },
  mixins: [loginRedirectMixin, redirectMixin],
  data() {
    return {
      clipboardValue: 'destination',
      emailText: {
        subject: 'You\'ve been invited to join your team on SolidProfessor!',
        body: 'SolidProfessor is the largest online training platform for engineering design with over 7,500 ' +
          'tutorials on CAD, CAM, BIM, and more. Explore online courses, video lessons, guided exercises, and ' +
          'quizzes to help you reach your design goals and get more done. \n\nGet started with Solidprofessor:\n\n'
      },
    };
  },
  created() {
    this.clipboardValue = this.computePlaceholder();
  },
  methods: {
    /**
     * Formats the placeholder string for the form copy-link box
     * @return {string}
     */
    computePlaceholder() {
      return `${this.SPENV.PLATFORM_FRONTEND}/activate-your-account/?admin_id=${this.$route.query.admin_id}`;
    },
    /**
     * Encodes the body of the mailto:
     * TODO: Update with env of the actual url
     *
     * @returns {string}
     */
    getEmailBody () {
      return encodeURIComponent(
        `${this.emailText.body}
         ${this.clipboardValue}`
      );
    },
  }
};
</script>

<style lang="scss" scoped>
.admin-invite {
  .invite-text {
    font-size: 10pt;
  }
}
</style>
